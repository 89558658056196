// Accounting
import accounting from "accounting";
// Big JS
import Big from "big.js";
// Lodash
import _, { has, isArray, orderBy } from "lodash";
// Moment
import moment from "moment";

/**
 * Use this function to check the age of a dependent
 * @param {string or object}
 * returns true or false
 */
export const checkAge = (value: any): any => {
  const age = moment.utc().diff(value, "years");
  return age;
};

/**
 * Use this function to check if an object or a string is empty
 * @param {string or object}
 * returns true or false
 */
export const isEmpty = (value: any): boolean =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

/**
 * Pass this function a phone number
 * and it will normalize the phone number by striping away all spaces, dashes and hyphens
 * @param {string} phoneNum
 * @return {*}  {string}
 */
export const normalizePhone = (phoneNum: string): string => {
  return phoneNum.replace(/[- )(]/g, "");
};

/**
 * Pass this function a phone number
 * and it will format the phone number using the Canadian/US number format
 * @param {string} phoneNum
 * @return {*}  {string}
 */
export const formatPhone = (phoneNum: string): string => {
  let regexObj =
    /^(?:\+?1[-. ]?)?(?:\(?([0-9]{3})\)?[-. ]?)?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (regexObj.test(phoneNum)) {
    let parts: any = phoneNum.match(regexObj);
    let phone = "";
    if (parts[1]) {
      phone += "+1 (" + parts[1] + ") ";
    }
    phone += parts[2] + "-" + parts[3];
    return phone;
  } else {
    return phoneNum;
  }
};

/**
 * Pass this function a dollar amount
 * and it will format the dollar amount using the Canadian/US currency format
 * @param {*} amount
 * @return {*}  {*}
 */
export const formatCurrency = (amount: any): any => {
  if (amount && amount !== "" && !isNaN(amount)) {
    const parsedAmount: any = parseFloat(amount);
    let formatter: any = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    let value: any = formatter.format(parsedAmount);
    return value;
  } else {
    return `$0.00`;
  }
};

/**
 * Pass this function a regular  numbers
 * and it will format the regular  numbers using the Canadian/US currency format
 * @param {(number | string)} amount
 * @return {*}  {(number | string)}
 */
export const formatNumber = (amount: number | string): number | string => {
  return amount && amount.toString().length > 0
    ? parseInt(amount.toString()).toLocaleString()
    : 0;
};

/**
 * Pass this function a regular  numbers
 * and it will decorate the  number as a percentage
 * @param {(number | string)} amount
 * @return {*}  {string}
 */
export const formatPercentage = (amount: number | string): string => {
  if (amount && amount.toString().trim() !== "") {
    amount.toString().replace("%", "");
    return `${parseInt(amount.toString()).toString()}${"%"}`;
  }
  return `0%`;
};

/**
 * Pass this function a dollar amount
 * and it will clean up and return a float
 * @param {(number | string)} amount
 * @return {*}  {*}
 */
export const cleanUpCurrency = (amount: number | string): any => {
  return accounting.parse(amount);
};

/**
 * Pass this function a Number amount
 * and it will clean up and return a float
 * @param {(number | string)} amount
 * @return {*}  {*}
 */
export const cleanUpNumber = (amount: number | string): any => {
  return accounting.parse(amount);
};

/**
 * Pass this function a Percentage amount
 * and it will clean up and return a float converted
 * to the actual percentage value after division by 100
 * @param {*} amount
 * @return {*}  {*}
 */
export const cleanUpPercentage = (amount: any): any => {
  if (!amount) return 0;
  if (amount.toString().includes("%")) {
    let result =
      amount && amount.length > 0
        ? parseFloat(
            (
              parseFloat(amount.replace("%", "").replace(",", "")) / 100
            ).toString()
          )
        : 0;
    return result;
  }
  let altResult = amount
    ? parseFloat((parseFloat(amount) / 100).toString())
    : 0;
  return altResult;
};

/**
 * Pass this function a Percentage amount
 * and it will clean up and return a float, NO division done.
 * @param {*} amount
 * @return {*}  {*}
 */
export const removePercentDecoration = (amount: any): any => {
  if (amount.toString().includes("%")) {
    let result =
      amount && amount.length > 0
        ? parseFloat(amount.replace("%", "").replace(",", ""))
        : 0;
    return result;
  }
  let altResult = amount ? parseFloat(amount) : 0;
  return altResult;
};

/**
 * Pass this function a percentage decimal amount
 * and it will multiply by 100 and return original percentage value
 * @param {number} amount
 * @return {*}  {number}
 */
export const restorePercentageValue = (amount: number): number => {
  let result = amount ? amount * 100 : 0;
  return result;
};

/**
 * @param {*} f
 * @param {*} g
 */
const compose2 =
  (f: any, g: any) =>
  (...args: any[]) =>
    f(g(...args));

/**
 * @param {...Array<Function>} fns
 */
export const compose = (...fns: Array<Function>) => fns.reduce(compose2);

/**
 * Use this function to catch async/await errors without
 * using a try catch block in the style of golang error handling.
 * wrap function to be awaited in pro()
 * eg. const [err, data] = await pro(this.props.asyncMethod({});
 * @param {Promise<any>} promise
 * @return {*}  {Promise<any>}
 */
export function pro(promise: Promise<any>): Promise<any> {
  return promise.then((data) => [null, data]).catch((err) => [err]);
}

/**
 * Pass this function a string or array that refers to a property in a nested object 'nestedObj'
 * and it will resolve the correct field:
 * @param {*} [obj={}]
 * @param {(string | Array<string>)} path
 * @param {*} [defaultValue]
 * @return {*}  {*}
 */
export function get(
  obj: any = {},
  path: string | Array<string>,
  defaultValue?: any
): any {
  const paths: any = Array.isArray(path) ? path : path.split(".");

  if (paths.length > 1 && obj[paths[0]]) {
    return get(obj[paths[0]], paths.slice(1));
  }

  if (paths.length === 1 && obj[paths[0]] !== undefined) {
    return obj[paths[0]];
  }
  return defaultValue;
}

/**
 * @param {string} str
 * @param {number} limit
 * @return {*}  {boolean}
 */
export const validateLength = (str: string, limit: number): boolean =>
  str && str.split("").length > limit ? true : false;

/**
 * Can be used as a shared empty function so that when
 * an empty function is needed, there is no need to declare another.
 * For example, may be used in default prop-types to return an empty
 * function when no function passed in.
 * @param {*} f
 * @return {*}  {*}
 */
export const noop = (f: any): any => f;

/**
 * @param {number} ms
 * @return {*}  {Promise<void>}
 */
export const delay = (ms: number): Promise<void> =>
  new Promise((res) => setTimeout(res, ms));

/**
 * @export
 * @param {number} range
 * @return {*}  {string}
 */
export function randomId(range: number): string {
  return Math.random()
    .toString(36)
    .substring(2, range + 2);
}

/**
 * @export
 * @param {string} input
 * @return {*}  {boolean}
 */
export function isEmailValid(input: string): boolean {
  const re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(input).toLowerCase());
}

/**
 * @export
 * @param {string} value
 * @return {*}  {(Array<string> | null)}
 */
export function parseEmailsFromString(value: string): Array<string> | null {
  const splitEmails = value.trim().split(/[\s,;\t\n]+/);

  if (splitEmails.every((splitEmail: string) => isEmailValid(splitEmail))) {
    return Array.from(new Set(splitEmails));
  }
  return null;
}

/**
 * @param {*} keysMap
 * @param {*} obj
 * @return {*}  {*}
 */
export const renameKeys = (keysMap: any, obj: any): any => {
  for (let item in obj) {
    obj[item] =
      typeof obj[item] === "string"
        ? obj[item].replace("$", "").replace(",", "").replace("%", "")
        : _.isObject(obj[item])
        ? obj[item]["value"]
        : obj[item];
  }
  return Object.keys(obj).reduce((acc, key) => {
    const renamedObject = {
      [keysMap[key] || key]: obj[key],
    };
    return {
      ...acc,
      ...renamedObject,
    };
  }, {});
};

/**
 * @param {*} obj
 * @param {*} endDateYear1
 * @param {*} startDateYear1
 * @param {boolean} [prorateYear1Id=false]
 * @param {*} paymentSchedule
 * @return {*}  {*}
 */
export const buildPDVArray = (
  obj: any,
  endDateYear1: any,
  startDateYear1: any,
  prorateYear1Id: boolean = false,
  paymentSchedule: any
): any => {
  let result: any = { 1: {}, 2: {}, 3: {}, 4: {}, 5: {} };
  for (let item in obj) {
    if (item.includes("employeeClass")) {
      let key = item.replace("employeeClass", "")[0];
      let currentPath = item.replace("employeeClass", "");
      currentPath = currentPath.replace(key, "");
      result[key][currentPath.charAt(0).toLowerCase() + currentPath.slice(1)] =
        obj[item];
    }
  }
  result = Object.values(result);
  let percentageRenameSub = {
    annualContribution: "annualContributionPercent",
    annualContributionPlus1: "annualContributionPercentPlus1",
    annualContributionPlus2: "annualContributionPercentPlus2",
  };

  let newResult: any = result.map((item: any) => {
    if (item.contributionAmountType === "percentage") {
      return renameKeys(percentageRenameSub, item);
    }
    return item;
  });

  newResult = newResult.filter((value: any) => JSON.stringify(value) !== "{}");

  //quarterly
  // newResult = newResult.map((item: any) => {
  //   let newQuarterlyValues = {};
  //   if (paymentSchedule === "Quarterly") {
  //     newQuarterlyValues = {
  //       quarterlyContribution: new Big(
  //         cleanUpCurrency(item.annualContribution || 0)
  //       )
  //         .div(4)
  //         .toString(),
  //       quarterlyContributionPlus1: new Big(
  //         cleanUpCurrency(item.annualContributionPlus1 || 0)
  //       )
  //         .div(4)
  //         .toString(),
  //       quarterlyContributionPlus2: new Big(
  //         cleanUpCurrency(item.annualContributionPlus2 || 0)
  //       )
  //         .div(4)
  //         .toString(),
  //     };
  //   }
  //   return { ...item, ...newQuarterlyValues };
  // });

  if (endDateYear1 && startDateYear1 && prorateYear1Id) {
    //calculate prorated amounts
    let endDateTime = new Date(moment.utc(endDateYear1).toDate()).getTime();
    let startDateTime = new Date(moment.utc(startDateYear1).toDate()).getTime();
    let dailyDiffMilliSeconds = new Big(endDateTime).minus(startDateTime);
    let dailyDiff = new Big(dailyDiffMilliSeconds)
      .div(new Big(1000).times(3600).times(24))
      .toNumber();

    newResult = newResult.map((item: any) => {
      let myProratedValues = {};
      if (
        prorateYear1Id &&
        moment
          .utc(endDateYear1)
          .diff(moment.utc(startDateYear1), "days", true) +
          1 <
          365
      ) {
        let numberOfDaysInPlanYear = new Big(dailyDiff).add(1);
        myProratedValues = {
          annualProrated: new Big(cleanUpCurrency(item.annualContribution || 0))
            .div(365)
            .times(numberOfDaysInPlanYear)
            .toString(),
          annualProratedPlus1: new Big(
            cleanUpCurrency(item.annualContributionPlus1 || 0)
          )
            .div(365)
            .times(numberOfDaysInPlanYear)
            .toString(),
          annualProratedPlus2: new Big(
            cleanUpCurrency(item.annualContributionPlus2 || 0)
          )
            .div(365)
            .times(numberOfDaysInPlanYear)
            .toString(),
        };
      }
      return { ...item, ...myProratedValues };
    });
  }

  return newResult;
};

/**
 * @return {*}  {*}
 */
export const uuidv4Generator = (): any => {
  return ("" + [1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => {
    let ch: any = Number(c);
    return (
      ch ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (ch / 4)))
    ).toString(16);
  });
};

/**
 * Pass this function an account number greater than 4 digits
 * and it will return a masked version
 * @param {*} account
 * @return {*}  {*}
 */
export const maskAccountNumber = (account: any): any => {
  let strAccount: any = account && account.toLocaleString().trim();
  if (strAccount.length > 4) {
    let fullLength: any = strAccount.length;
    const last4Digits: any = strAccount.slice(-4);
    const maskedNumber: any = last4Digits.padStart(fullLength, "*");
    return maskedNumber;
  }
  return strAccount;
};

/**
 * Pass metaInformation from invite
 * @param {metaInformation} data from invite
 * @returns cleaned up MetaData
 */
export const cleanUpMeta = (data: any): any => {
  let myMetaData: any = {};

  if (
    data &&
    isArray(data) &&
    typeof data !== "undefined" &&
    typeof data.forEach === "function"
  ) {
    data.forEach((item: any) => {
      if (item.metaType === "string" && !myMetaData[item.metaKey]) {
        myMetaData[item.metaKey] = item.metaValue;
      }
    });
  } else {
    myMetaData = { ...data };
  }

  return myMetaData;
};

/**
 * @param {booleanCandidate} valueType any
 * @returns boolean
 */
export const isTrue = (value: any): boolean => {
  if (typeof value === "string") {
    value = value.trim().toLowerCase();
  }
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
};

/**
 * @param {Array<any>} list
 * @param {string} listKey
 * @param {("asc" | "desc")} orderType
 * @return {*}  {Array<string>}
 */
export const changeListOrder = (
  list: Array<any>,
  listKey: string,
  orderType: "asc" | "desc"
): Array<string> =>
  orderBy(list, [(pay: any) => pay[listKey.toLowerCase()]], [orderType]);

const minimumAllowedCharacters = 8;

export const isMinimumCharactersValid = (password: string) =>
  password.length >= minimumAllowedCharacters;

export const hasNumericCharacterRuleIsValid = (password: string) =>
  /\d/.test(password);

export const hasCapitalCaseLetterRuleIsValid = (password: string) =>
  /(?=.*[A-Z])/.test(password);

export const hasSymbolCharacterIsValid = (password: string) =>
  /(?=.*[$@$!%*#+=?&_])/.test(password);

type MetaInformationType = {
  waivedWaitingPeriod: string;
  classLabel: string;
  dateOfBirth: string;
  hireDate: string;
  familyStatus: string;
  initialSubmissionDate: string;
};

export const parseMetaData = (data: any): MetaInformationType => {
  const parsedMetaData: any = {};
  if (
    has(data, "person.metaInformation") &&
    typeof data.person.metaInformation !== "undefined" &&
    typeof data.person.metaInformation.forEach === "function"
  ) {
    data.person.metaInformation.forEach((item: any) => {
      if (item.metaType === "string") {
        parsedMetaData[item.metaKey] = item.metaValue;
      }
    });
  } else if (get(data, "person.metaInformation")) {
    Object.keys(data.person.metaInformation).forEach((item) => {
      if (get(data, `person.metaInformation[${item}].metaKey`)) {
        parsedMetaData[data.person.metaInformation[item].metaKey] =
          data.person.metaInformation[item].metaValue;
      }
      parsedMetaData[item] = data.person.metaInformation[item];
    });
  }
  return parsedMetaData;
};

export const flattenObject = (object) => {
  return (
    object &&
    object !== null &&
    Object.assign(
      {},
      ...(function _flatten(o) {
        return [].concat(
          ...Object.keys(o).map((k) =>
            typeof o[k] === "object" ? _flatten(o[k]) : { [k]: o[k] }
          )
        );
      })(object)
    )
  );
};

export const renderDate = (
  date: string,
  formatString: string = "MMM DD, YYYY"
) => {
  return date && moment(date).utcOffset(0).format(formatString);
};

export const renderTime = (date: string) =>
  date && moment(date).utcOffset(0).format("LTS");

export const calculateInMonths = (days: number) => {
  if (days > 12) {
    switch (days) {
      case 30:
        return 1;
      case 60:
        return 2;
      case 90:
        return 3;
      default:
        return days;
    }
  } else {
    return days;
  }
};

export const StringToTitleCase = (str: string): string => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};
